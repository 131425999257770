import React, { useEffect, useState } from 'react';
import HeroCarousel from '../components/UI/HeroCarousel';
import styles from './Home.module.css';
import PromotionsStyles from './Promotions.module.css';

import offer from './../assets/images/shapes/special-tag.png';
import pinal from './../assets/images/about/pinal.jpeg';
import peacock from './../assets/images/shapes/feather3.png';
import peacockLeft from './../assets/images/shapes/feather4.png';

import { Link } from 'react-router-dom';

import { getSimpleServiceCategories } from '../services/api/Service';
import SEO from '../SEO/SEO';
import { latest } from '../services/api/Promotion';

const Home = (props) => {
  const [services, updateServices] = useState([]);
  const [latestPromotion, updateLatestPromotion] = useState(null);

  useEffect(() => {
    getSimpleServiceCategories()
      .then((services) => {
        updateServices(services);
      })
      .catch((error) => console.log('Something happened!'));

    latest()
      .then((promotion) => {
        updateLatestPromotion(promotion);
      })
      .catch((error) => console.log('Something happened!'));
  }, []);

  return (
    <>
      <SEO
        title={'Welcome to Beauty Plus Brow Studio'}
        description={`Welcome to Beauty Plus Brow Studio; where you can relax and enjoy our best services. We provide threading, waxing, facial, lash extension, cosmetic teeth whiting and much more.
          `}
        name={'Beauty Plus Brow Studio'}
        type={'page'}
      />
      <section className='bg-light-pink pb-5'>
        <section className='hero-section rel z-1 bg-butter'>
          <HeroCarousel />
        </section>

        <section className='service-section rel z-1 pt-150 pb-5'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-3 align-self-end'></div>
              <div className='col-xl-6'>
                <div className={styles['features-section-content']}>
                  <div
                    className={` section-title ${styles['section-title']} text-center mb-3 `}
                  >
                    <span className={`bg-text ${styles['bg-text']}`}>
                      Services
                    </span>
                    <span className='sub-title' style={{ fontSize: '2rem' }}>
                      Beauty Plus Brow Studio
                    </span>
                    <h2>
                      Specializing in enhancing natural beauty using semi
                      permanent makeup technique to make you feel confident &
                      look younger
                    </h2>
                  </div>
                </div>
              </div>
              <div className='col-xl-3'></div>
            </div>
          </div>
          <div className={styles['features-leaf-right']}>
            <span>Radhe Radhe</span>
            <img src={peacock} alt='Leaf' />
          </div>
          <div className={styles['features-leaf-left']}>
            <span>Radhe Radhe</span>
            <img src={peacockLeft} alt='Leaf' />
          </div>
        </section>

        <section className={`${styles['project-section']} pt-5 pb-5 mb-5`}>
          <div className='container'>
            <div className='row justify-content-center'>
              {services.map((service, index) => {
                const content = (
                  <div
                    className={`col-lg-4 col-sm-6 ${styles['col-lg-4']} ${styles['col-sm-6']}`}
                    key={service.id}
                  >
                    <div className={`${styles['project-item']}`}>
                      <img src={service.image} alt='Feature' />
                      <div className={styles['project-content']}>
                        <h5>
                          <Link to={`/services/${service.slug}`}>
                            {service.name}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                );

                return content;
              })}

              <div className='item col-lg-12 mt-5 pt-5'>
                <div className={`${styles['project-btn']} text-center`}>
                  <Link to={'/services'} className='theme-btn'>
                    view all <i className='bi bi-arrow-right'></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='booking-hours-area bg-light-gray rel z-1'>
          <div
            className={`${styles['booking-hour-image']} bgs-cover`}
            style={{ backgroundImage: `url(${pinal})` }}
          ></div>

          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 offset-lg-6 pt-lg-5 pb-lg-5'>
                <div
                  className={`${styles['booking-hours-content']} pt-lg-5 pb-5`}
                >
                  <div className='section-title mb-30'>
                    <span className='bg-text'>Timing</span>
                    <span className='sub-title'>Beauty Plus Brow Studio</span>
                    <h2>Working Hours</h2>
                  </div>
                  <p>
                    We welcome
                    <strong className='color-black'> WALK-INS</strong> for most
                    of our services, You can visit our place at following hours.
                  </p>
                  <ul className={`${styles['booking-hours']} pt-3 pb-5`}>
                    <li>
                      <span className={styles['date']}>Monday to Friday</span>
                      <span className='symbol'>:</span>
                      <span className='time'>10:00 am — 8:00 pm</span>
                    </li>
                    <li>
                      <span className={styles['date']}>Saturday</span>
                      <span className='symbol'>:</span>
                      <span className='time'>10:00 am — 6:00 pm</span>
                    </li>
                    <li>
                      <span className={styles['date']}>Sunday</span>
                      <span className='symbol'>:</span>
                      <span className='time'>11:00 am — 5:00 pm</span>
                    </li>
                  </ul>
                  <Link to='/contact' className='theme-btn style-six'>
                    Contact Us <i className='bi bi-arrow-right'></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['booking-bg-icon']}></div>
        </section>

        <section className='booking-section-three z-1 pt-140 pb-80'>
          <div className='container-fluid'>
            <div className='row d-flex justify-content-center px-2 px-xl-5'>
              <div className='col-xl-5 col-lg-9 mb-3'>
                <div className='booking-three-content pr-5 '>
                  <div className='section-title'>
                    <span className='bg-text'>Offer</span>
                    <span className='sub-title'>Big Discounts on</span>
                    <h2>Special Offers</h2>
                  </div>
                  <p className='lead'>
                    Throughout the year, we occasionally offer discounted prices
                    and packages on our holiday promotions...
                  </p>
                  <Link to='special-offers' className='read-more'>
                    learn more <i className='fas fa-long-arrow-alt-right'></i>
                  </Link>
                </div>
              </div>
              <div className='offer-card col-xl-7 col-lg-10 d-flex justify-content-center'>
                {latestPromotion !== null && (
                  <div
                    className={`${PromotionsStyles['booking-item']} row`}
                    style={{
                      background: '#ffd1ea9e',
                      margin: '0px',
                    }}
                  >
                    <div
                      className={`${
                        latestPromotion.type === 'large' && 'col-md-7'
                      } ${!latestPromotion.type === 'large' && 'col-md-6'}`}
                      style={{ padding: '0px' }}
                    >
                      <img
                        src={latestPromotion.image}
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <div
                      className={`${PromotionsStyles['booking-content']} ${
                        latestPromotion.type === 'large' && 'col-md-5'
                      } ${!latestPromotion.type === 'large' && 'col-md-6'}`}
                    >
                      <h2>{latestPromotion.title}</h2>
                      <h5>{latestPromotion.subtitle}</h5>
                      <div className={PromotionsStyles['promotion-dates']}>
                        <div className='widget-date-primary'>
                          <span className='date-day'>
                            {latestPromotion.start_date}
                          </span>
                        </div>

                        <div className='widget-date-primary'>
                          <span className='date-day'>
                            {latestPromotion.end_date}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {latestPromotion == null && <img src={offer} alt='' />}
              </div>
            </div>
          </div>
        </section>

        <section className={`${styles['booking-section']}`}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-6'>
                <div className={styles['booking-item']}>
                  <div
                    className={`${styles['booking-image']} ${styles['membership']}`}
                  ></div>
                  <div className={styles['booking-content']}>
                    <span className={`bg-text ${styles['bg-text']}`}>Card</span>
                    <h3>Membership Card</h3>
                    <p>
                      <ul className='list-style-one'>
                        <li>Become our loyal member</li>
                        <li>Collect points and Save money</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-xl-6'>
                <div className={styles['booking-item']}>
                  <div
                    className={`${styles['booking-image']} ${styles['gift']}`}
                  ></div>
                  <div className={styles['booking-content']}>
                    <span className={`bg-text ${styles['bg-text']}`}>Card</span>
                    <h3>Gift Card</h3>
                    <p>
                      <ul className='list-style-one'>
                        <li>Gift cards available</li>
                        <li>Get a gift card for your loved one</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Home;
