import APIClient from './APIClient';

export const loadImages = (offset, limit) => {
  return APIClient.get(`/gallery/load/?offset=${offset}&limit=${limit}`).then(
    (response) =>
      response.data.map((gallery) => {
        return { ...gallery, key: gallery.id, src: gallery.image };
      })
  );
};
