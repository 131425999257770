import APIClient from './APIClient';

export const getSimpleServiceCategories = () => {
  return APIClient.get('/service-categories/all-simple/').then(
    (response) => response.data
  );
};

export const getServiceCategories = () => {
  return APIClient.get('/service-categories/').then(
    (response) => response.data
  );
};

export const getServiceCategory = (id) => {
  return APIClient.get(`/service-categories/${id}`).then(
    (response) => response.data
  );
};

export const getServiceCategoryBySlug = (slug) => {
  return APIClient.get(`/service-categories/slug/?slug=${slug}`).then(
    (response) => response.data
  );
};

export const getRandomServiceCategory = () => {
  return APIClient.get('/service-categories/random').then(
    (response) => response.data
  );
};
