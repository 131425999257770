import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';

import logoImg from './../../assets/images/logo.png';
import { Link, NavLink } from 'react-router-dom';

const Header = (props) => {
  const [isScrolled, toggleTopBar] = useState(false);
  const [isMobile, toggleNavBar] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 80 && window.scrollY < 90) toggleTopBar(true);

      if (window.scrollY < 50) toggleTopBar(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNavBarHandler = () => {
    toggleNavBar((previousState) => !previousState);
  };

  const removeMobileNavbar = () => {
    if (isMobile) toggleNavBar(false);
  };

  return (
    <header
      className={`${styles['main-header']} ${styles['header-three']} 
      ${styles[isScrolled ? 'fixed-header' : '']}`}
    >
      <div className={`bg-pink text-black py-2 ${styles['header-top-wrap']}`}>
        <div className='container'>
          <div className={styles['header-top']}>
            <ul>
              <li>
                <i className='bi bi-telephone-fill'></i>
                (639) 916 1144 <i className='bi bi-phone-fill pl-2'></i> (306)
                216 2827
              </li>
              <li>
                <i className='bi bi-geo-alt-fill'></i> Devonshire Mall, 3100
                Howard Ave, Windsor, ON, N8X 3Y8
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <!--Header-Upper--> */}
      <div className={styles['header-upper']}>
        <div className='container-fluid'>
          <div className='header-inner d-flex align-items-center'>
            <div className={styles['logo-outer']}>
              <div className={`${styles.logo} mt-3`}>
                <Link to={'/'}>
                  <img src={logoImg} alt='Logo' title='Logo' />
                </Link>
              </div>
            </div>

            <div className={`${styles['nav-outer']}`}>
              {/* <!-- Main Menu --> */}
              <nav className={`${styles['main-menu']} navbar-expand-lg`}>
                <div className={`${styles['navbar-header']}`}>
                  <button
                    type='button'
                    className={`navbar-toggle ${styles['navbar-toggle']}`}
                    onClick={toggleNavBarHandler}
                  >
                    <span className={styles['icon-bar']}></span>
                    <span className={styles['icon-bar']}></span>
                    <span className={styles['icon-bar']}></span>
                  </button>

                  <div className={` ${styles['mobile-logo']} p-3 m-auto`}>
                    <a href='index.html'>
                      <img src={logoImg} alt='Logo' title='Logo' />
                    </a>
                  </div>
                </div>

                <div
                  className={`navbar-collapse collapse ${
                    styles['navbar-collapse']
                  } ${styles.collapse}  ${isMobile ? 'show' : ''} ${
                    styles[isMobile ? 'show' : '']
                  }`}
                >
                  <ul className='navigation clearfix'>
                    <li>
                      <NavLink to={'/'} onClick={removeMobileNavbar}>
                        Home
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink to={'/about'} onClick={removeMobileNavbar}>
                        About
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink to={'/services'} onClick={removeMobileNavbar}>
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={'/gallery'} onClick={removeMobileNavbar}>
                        Gallery
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={'/special-offers'}
                        onClick={removeMobileNavbar}
                      >
                        Special Offers
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={'/career'} onClick={removeMobileNavbar}>
                        Career
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={'/contact'} onClick={removeMobileNavbar}>
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* <!-- Main Menu End--> */}
            </div>

            {/* <!-- Menu Button --> */}
            <div className={`${styles['menu-icons']} d-none d-sm-flex`}>
              <div className={styles['menu-sidebar']}>
                <Link to={'/contact/book'} className='btn theme-btn style-two'>
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
