import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = (props) => {
  return (
    <footer
      className={`${styles['main-footer']} ${styles['footer-three']} bg-black text-white`}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-sm-6 d-none d-md-flex'>
            <div
              className={`${styles['footer-widget']} ${styles['menu-widget']}`}
            >
              <h4 className={styles['footer-title']}>Favorite</h4>
              <ul>
                <li>
                  <Link to='/special-offers'>Discounts</Link>
                </li>
                <li>
                  <Link to='/special-offers'>Special Offers</Link>
                </li>
                <li>
                  <Link to='/gallery'>Recent Looks</Link>
                </li>
                <li>
                  <Link to='/career'>Career Opportunities</Link>
                </li>
                {/* <li>
                  <Link to='/about'>About Us</Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6 d-none d-md-flex'>
            <div
              className={`${styles['footer-widget']} ${styles['menu-widget']}`}
            >
              <h4 className={styles['footer-title']}>Services</h4>
              <ul>
                <li>
                  <Link to='/services/threading'>Threading Services</Link>
                </li>
                <li>
                  <Link to='/services/tinging'>Tinting Services</Link>
                </li>
                <li>
                  <Link to='/services/henna-brow'>Henna Brow</Link>
                </li>
                <li>
                  <Link to='/services/lash-extensions'>Lash Extensions</Link>
                </li>
                <li>
                  <Link to='/services/eyebrow-waxing'>Waxing Services</Link>
                </li>
                <li>
                  <Link to='/services/skin-care'>Facial Services</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <div
              className={`${styles['footer-widget']} ${styles['contact-widget']} mb-3`}
            >
              <h4 className={styles['footer-title']}>Contact</h4>
              <ul>
                <li>
                  <i className='bi bi-geo-alt-fill'></i>
                  <div className={styles['info-content']}>
                    <h5>Location</h5>
                    <p className='mb-0'>Devonshire Mall</p>
                    <p className='mb-0'>
                      3100 Howard Ave, Windsor, ON, N8X 3Y8
                    </p>
                    <hr />
                    <p className='mb-0'>Tecumseh Mall</p>
                    <p className='mb-0'>
                      7654 Tecumseh Rd E, Windsor, ON, N8T 1E9
                    </p>
                  </div>
                </li>
                <li>
                  <i className='bi bi-telephone-fill'></i>
                  <div className={styles['info-content']}>
                    <h5>Telephone</h5>
                    <p className='mb-0'>
                      <a href='callto:+16399161144'>(639) 916 1144</a>
                    </p>
                    <p className='mb-0'>
                      <a href='callto:+13062162827'>(306) 216 2827</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <div
              className={`${styles['footer-widget']} ${styles['contact-widget']} pt-md-5`}
            >
              <ul>
                <li className='pt-md-3'>
                  <i className='bi bi-chat-fill'></i>
                  <div className={styles['info-content']}>
                    <h5>Email</h5>
                    <a href='mailto:info@beautyplusbrowstudio.ca'>
                      info@beautyplusbrowstudio.ca
                    </a>
                  </div>
                </li>

                <li>
                  <i className='bi bi-clock-fill'></i>
                  <div className={styles['info-content']}>
                    <h5>Timing</h5>
                    <p className='mb-0'>Monday - Friday, 10am - 08pm</p>
                    <p className='mb-0'>Saturdays, 10am - 06pm</p>
                    <p className='mb-0'>Sundays, 11am - 05pm</p>
                  </div>
                </li>

                <li>
                  <i className='bi bi-hand-index-thumb-fill'></i>
                  <div className={styles['info-content']}>
                    <h5>Follow us</h5>
                  </div>
                </li>
              </ul>
              <div className={`social-style-one ${styles['social-style-one']}`}>
                <a href='' target='_blank' rel='noreferrer'>
                  <i className='bi bi-facebook'></i>
                </a>
                <a href='' target='_blank' rel='noreferrer'>
                  <i className='bi bi-instagram'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['copyright-area']} py-4`}>
          <ul className={`${styles['footer-menu']}`}>
            <li>
              <a href='mailto:spozhmay.ikhlas@gmail.com'>Contact</a>
            </li>
            <li>
              <a href='callto:+16477846188'>(647) 784-6188</a>
            </li>
          </ul>
          <p>© 2022 MOON Design & Development</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
