import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Layout from './components/Layout/Layout';
import Home from './pages/Home';

import './App.css';
import About from './pages/About';
import Services from './pages/Services';
import ServiceDetails from './pages/ServiceDetails';
import Gallery from './pages/Gallery';
import Promotions from './pages/Promotions';
import Contact from './pages/Contact';
import Career from './pages/Career';

function App() {
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          {/* <Route path='/about' element={<About />} /> */}
          <Route path='/services/:slug' element={<ServiceDetails />} />
          <Route path='/services' element={<Services />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/special-offers' element={<Promotions />} />
          <Route path='/contact/:book' element={<Contact />} />
          <Route path='/career' element={<Career />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </Layout>
    </HelmetProvider>
  );
}

export default App;
