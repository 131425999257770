import React from 'react';

import styles from './Career.module.css';
import SEO from '../SEO/SEO';
import banner from './../assets/images/banners/eyebrow-threading.jpeg';

const Career = (props) => {
  return (
    <>
      <SEO
        title={'Career -- Beauty Plus Brow Studio'}
        description={`Looking for an experienced eyebrow threading technician to join
        our team, & willing to learn more services & develop new skills.
        Person must have good communication & customer service skill.`}
        name={'Beauty Plus Brow Studio'}
        type={'page'}
      />
      <section
        className={`page-banner text-white ${styles['page-banner']} d-flex align-items-center`}
        style={{
          backgroundImage: `url(${banner})`,
        }}
      >
        <div className='container'>
          <div className='banner-inner d-flex justify-content-center'></div>
        </div>
      </section>
      <section className='career-section pt-80 pb-80 bg-light-pink '>
        <div className='container'>
          <div className='section-title  pb-45'>
            <div className='text-center'>
              <span className='bg-text'>Career</span>
              <span className='sub-title'>Join Our Team</span>
              <h2 className='mb-4'>We are Hiring</h2>
            </div>
            <h5>
              Looking for an experienced eyebrow threading technician to join
              our team, & willing to learn more services & develop new skills.
              Person must have good communication & customer service skill.
            </h5>
            <br />
            <h5>Skills required are the following:</h5>
            <ul>
              <li>
                <strong>Threading, Waxing & Facials skills are must</strong>
              </li>
              <li>Tinting</li>
              <li>Henna Brow</li>
              <li>Lash Extensions</li>
              <li>Lash Lift & Tint</li>
              <li>Other beauty services</li>
            </ul>

            <br />

            <h5>
              Technician must be able to demonstrate in person Threading
              techniques on their own model during interview.
            </h5>
            <h5>
              To see us in person come to{' '}
              <a
                href='https://goo.gl/maps/ArWWFbSrhrQiKqmCA'
                target='_blank'
                rel='noreferrer'
                className='color-pink'
              >
                Devonshire Mall @ Beauty Plus Brow Studio
              </a>{' '}
              OR send us your resume to{' '}
              <a
                className='color-pink'
                href='mailto:info@beautyplusbrowstudio.ca'
              >
                info@beautyplusbrowstudio.ca
              </a>{' '}
              OR call us at{' '}
              <a className='color-pink' href='callto:+16399161144'>
                (639) 916 1144
              </a>{' '}
              ,{' '}
              <a className='color-pink' href='callto:+13062162827'>
                (306) 216 2827.
              </a>
            </h5>

            <h5>
              {/* Come to see in person at Devonshire mall @ Beauty Plus Brow studio */}
            </h5>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
