import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { bookAppointment } from '../services/api/Appointment';
import styles from './BookAppointment.module.css';

const BookAppointment = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [appointment, updateAppointment] = useState(null);

  const book = (data) => {
    console.log(data);
    bookAppointment(data)
      .then((data) => {
        updateAppointment({ ...data });
        reset();
      })
      .catch((error) => updateAppointment(null));
  };

  const handleNewAppointment = () => {
    updateAppointment(null);
  };
  return (
    <div className='pb-80 bg-light-pink'>
      <div className='container'>
        {appointment === null && (
          <div className={`${styles['contact-form-wrap']} bg-light-pink-2`}>
            <div className='section-title mb-45 text-center'>
              <h2 className='text-none'>Book Appointment</h2>
            </div>
            <form className='contact-form' onSubmit={handleSubmit(book)}>
              <div className='row justify-content-center'>
                <div className='col-md-4 col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='name'>
                      <i className='bi bi-person'></i>
                    </label>
                    <input
                      type='text'
                      placeholder='Full Name'
                      className={`form-control ${styles['form-control']} ${
                        errors.client?.name && 'is-invalid'
                      }`}
                      {...register('client.name', {
                        required: 'Please enter your name',
                      })}
                    />
                    <div className='form-text with-errors'>
                      {errors.client?.name?.message}
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='email'>
                      <i className='bi bi-envelope-at'></i>
                    </label>
                    <input
                      type='email'
                      placeholder='Email Us'
                      className={`form-control ${styles['form-control']} ${
                        errors.client?.email && 'is-invalid'
                      }`}
                      {...register('client.email', {
                        required: 'Please enter a valid email address',
                      })}
                    />
                    <div className='form-text with-errors'>
                      {errors.client?.email?.message}
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='phone'>
                      <i className='bi bi-phone'></i>
                    </label>
                    <input
                      type='tel'
                      placeholder='Phone'
                      className={`form-control ${styles['form-control']} ${
                        errors.client?.phone && 'is-invalid'
                      }`}
                      {...register('client.phone', {
                        required: 'Please enter your phone number',
                        minLength: {
                          value: 10,
                          message: 'Please enter valid phone number',
                        },
                        maxLength: {
                          value: 10,
                          message: 'Please enter valid phone number',
                        },
                      })}
                    />
                    <div className='form-text with-errors'>
                      {errors.client?.phone?.message}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <select
                      className={`form-select form-control  ${
                        styles['form-control']
                      } ${errors.service && 'is-invalid'}`}
                      {...register('service', { required: true })}
                    >
                      <option value=''>Services</option>
                      <option value='Lash Extension'>Lash Extension</option>
                      <option value='Cosmetic Teeth Whiting'>
                        Cosmetic Teeth Whiting
                      </option>
                    </select>
                  </div>
                </div>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <textarea
                      rows='5'
                      className={`form-control ${styles['form-control']}`}
                      placeholder='Write Message'
                      {...register('message')}
                    ></textarea>
                    <div className='form-text with-errors'></div>
                  </div>
                </div>
                <div className='col-lg-5 col-md-6 col-sm-8'>
                  <div className='form-group mb-0'>
                    <button type='submit' className='theme-btn w-100 style-six'>
                      book <i className='fas fa-long-arrow-alt-right'></i>
                    </button>
                    <div id='msgSubmit' className='hidden'></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        {appointment && (
          <div
            className={`${styles['contact-form-wrap']} ${styles['successful-appointment']}`}
          >
            <div className='section-title mb-45 text-center'>
              <h2 className='text-none'>Appointment Request Received</h2>
              <p>
                Dear <strong>{appointment.client?.name}</strong> , we have
                received you request to book an appointment for
                <strong> {appointment.service}</strong>. We will contact you
                back in <strong>one business day</strong> to confirm your
                booking.
              </p>

              <h6 onClick={handleNewAppointment}>Book Another Appointment</h6>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookAppointment;
