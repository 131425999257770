import React from 'react';
import BookAppointment from '../components/BookAppointment';
import SEO from '../SEO/SEO';
import styles from './Contact.module.css';

const Contact = (props) => {
  return (
    <>
      <SEO
        title={'Contact -- Beauty Plus Brow Studio'}
        description={`You can get the majority of our services by just walking into our
        salon or you can book an appointment with us for specific services.`}
        name={'Beauty Plus Brow Studio'}
        type={'page'}
      />
      <section className='contact-info-section pt-80 pb-80 bg-light-pink'>
        <div className='container'>
          <div className='section-title text-center mb-5 pb-5'>
            <span className='bg-text'>Contact</span>
            <span className='sub-title'>We Welcome</span>
            <h2 className='pb-3'>Walk-Ins</h2>
            <h4>
              You can get the majority of our services by just walking into our
              salon; no appointment or phone call is necessary, except for{' '}
              <strong>teeth whitening</strong> and{' '}
              <strong>lash extensions</strong>, which requires an appointment.
              You can give us a call or make an appointment online at the bottom
              of this page.
            </h4>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-sm-6'>
              <div className={styles['contact-info-item']}>
                <i className='bi bi-geo-alt-fill'></i>
                <h4>Location</h4>
                <p>
                  <a
                    href='https://goo.gl/maps/ArWWFbSrhrQiKqmCA'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Devonshire Mall <br /> 3100 Howard Ave, Windsor
                    <br /> ON, N8X 3Y8
                  </a>
                </p>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <div className={styles['contact-info-item']}>
                <i className='bi bi-geo-alt-fill'></i>
                <h4>Location</h4>
                <p>
                  <a
                    href='https://goo.gl/maps/7igbFncwUGwjapWN8'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Tecumseh Mall <br />
                    7654 Tecumseh Rd E, Windsor
                    <br /> ON, N8T 1E9
                  </a>
                </p>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <div className={styles['contact-info-item']}>
                <i className='bi bi-clock-fill'></i>
                <h4>Working Hours</h4>
                <p className='mb-0'>Monday - Friday, 10am - 8pm</p>
                <p className='mb-0'>Saturdays, 10am - 6pm</p>
                <p className='mb-0'>Sundays, 11am - 5pm</p>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <div className={styles['contact-info-item']}>
                <i className='bi bi-envelope-paper'></i>
                <h4>Email Us</h4>
                <p>
                  <a href='mailto:info@beautyplusbrowstudio.ca'>
                    info@beautyplusbrowstudio.ca
                  </a>
                </p>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <div className={styles['contact-info-item']}>
                <i className='bi bi-telephone-fill'></i>
                <h4>Call Us</h4>
                <p>
                  <a href='callto:+16399161144'>(639) 916 1144</a>
                  <br />
                  <a href='callto:+13062162827'>(306) 216 2827</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={`${styles['contact-page-map']} pb-80 bg-light-pink`}>
        <iframe
          title='google-map'
          style={{ border: 0, width: '100%', height: '450px' }}
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.107636794921!2d-83.0054035845397!3d42.276225648384276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2da55afb7509%3A0xd6286a127e8e1844!2sBeauty%20Plus%20Brow%20Studio%20(Windsor%20Devonshire%20Mall)!5e0!3m2!1sen!2sca!4v1670904384967!5m2!1sen!2sca'
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>

      <BookAppointment />
    </>
  );
};

export default Contact;
