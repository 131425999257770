import React, { useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

import './HeroCarousel.css';

const HeroCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Carousel
        className='carousel slide slider-section'
        prevIcon={<i className='bi bi-arrow-left-square-fill'></i>}
        nextIcon={<i className='bi bi-arrow-right-square-fill'></i>}
        prevLabel={''}
        nextLabel={''}
        indicators={false}
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item bsPrefix='carousel-item slider-single-item with-text-left'>
          <Carousel.Caption className='left'>
            <div className='container'>
              <h1>Highlight your Natural Beauty</h1>

              <div className='slider-btn'>
                <Link to='contact' className='theme-btn style-four'>
                  Walk-Ins Welcome!
                </Link>
                <Link to='services' className='theme-btn style-six'>
                  our service
                  <i className='fas fa-long-arrow-alt-right'></i>
                </Link>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item bsPrefix='carousel-item slider-single-item with-text-right'>
          <Carousel.Caption className='right'>
            <div className='container'>
              <h1>Discover the Beauty within You</h1>
              <div className='slider-btn'>
                <Link to='contact' className='theme-btn style-four'>
                  Walk-Ins Welcome!
                </Link>
                <Link to='services' className='theme-btn style-six'>
                  our service
                  <i className='fas fa-long-arrow-alt-right'></i>
                </Link>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item bsPrefix='carousel-item slider-single-item with-text-left'>
          <Carousel.Caption className='left'>
            <div className='container'>
              <h1>Glow like never before</h1>
              <div className='slider-btn'>
                <Link to='contact' className='theme-btn style-four'>
                  Walk-Ins Welcome!
                </Link>
                <Link to='services' className='theme-btn style-six'>
                  our service
                  <i className='fas fa-long-arrow-alt-right'></i>
                </Link>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item bsPrefix='carousel-item slider-single-item with-text-left'>
          <Carousel.Caption className=''>
            <div className='container'>
              <h1>Get the Best Look Ever</h1>
              <div className='slider-btn'>
                <Link to='contact' className='theme-btn style-four'>
                  Walk-Ins Welcome!
                </Link>
                <Link to='services' className='theme-btn style-six'>
                  our service
                  <i className='fas fa-long-arrow-alt-right'></i>
                </Link>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default HeroCarousel;
