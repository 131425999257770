import React, { useEffect, useState } from 'react';
import styles from './Promotions.module.css';

import { all } from './../services/api/Promotion';

import img from './../assets/images/slider/slider-8.png';
import SEO from '../SEO/SEO';

const Promotions = (props) => {
  const [promotions, updatePromotions] = useState([]);

  useEffect(() => {
    all()
      .then((data) => {
        console.log(data);
        updatePromotions(data);
      })
      .catch((error) => {
        console.log('something wrong happened!');
      });
  }, []);
  return (
    <>
      <SEO
        title={'Special Offers -- Beauty Plus Brow Studio'}
        description={`Throughout the year, we occasionally offer discounted prices
        and packages. For information on our holiday promotions.
        Check this section on a regular basis`}
        name={'Beauty Plus Brow Studio'}
        type={'page'}
      />
      <section className='pb-80 bg-light-pink'>
        <section className='pb-45'>
          <div className={`container-fluid ${styles['container-fluid']}`}>
            <div className='row'>
              <div className='col-lg-6'>
                <img src={img} alt='' />
              </div>
              <div className='col-lg-6 d-flex align-items-center text-center px-5'>
                <div className='booking-three-content pt-5 mt-3'>
                  <div className='section-title'>
                    <span className='bg-text'>Offers</span>
                    <span className='sub-title'>Discounts on</span>
                    <h2>Special Offers</h2>
                  </div>
                  <h4>
                    Throughout the year, we occasionally offer discounted prices
                    and packages. For information on our holiday promotions.
                    Check this section on a regular basis
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${styles['booking-section']} pb-20`}>
          <div className='container-fluid'>
            <div className='row'>
              {promotions.map((promotion) => {
                const isLarge = promotion.type === 'large';

                return (
                  <div
                    className={`${isLarge && 'col-md-12'} ${
                      !isLarge && 'col-md-6'
                    } d-flex align-item-center justify-content-center`}
                    key={promotion.id}
                  >
                    <div className={`${styles['booking-item']} row offer-card`}>
                      <div
                        className={`${isLarge && 'col-md-7'} ${
                          !isLarge && 'col-md-6'
                        } ${styles['booking-image']} `}
                      >
                        <img
                          src={promotion.image}
                          className='img-fluid'
                          alt=''
                        />
                      </div>
                      <div
                        className={`${styles['booking-content']} ${
                          isLarge && 'col-md-5'
                        } ${!isLarge && 'col-md-6'}`}
                      >
                        <h1>{promotion.title}</h1>
                        {/* <h2>{promotion.discount}%</h2> */}
                        <h3>{promotion.subtitle}</h3>
                        <div className={styles['promotion-dates']}>
                          <div className='widget-date-primary'>
                            <span className='date-day'>
                              {promotion.start_date}
                            </span>
                          </div>

                          <div className='widget-date-primary'>
                            <span className='date-day'>
                              {promotion.end_date}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Promotions;
