import React, { useEffect, useState } from 'react';
import styles from './ServiceDetails.module.css';

import {
  getRandomServiceCategory,
  getServiceCategoryBySlug,
} from '../services/api/Service';
import { Link, useParams } from 'react-router-dom';
import SEO from '../SEO/SEO';
import SubServiceModel from '../components/UI/SubServiceModel';

const ServiceDetails = (props) => {
  const { slug } = useParams();
  const [service, updateService] = useState(null);
  const [otherServices, updateOtherService] = useState([]);
  const [subService, setSubService] = useState({ show: false, service: null });

  const HandleSubService = (service) => {
    console.log(service);
    setSubService({ service: service, show: true });
  };

  useEffect(() => {
    getServiceCategoryBySlug(slug)
      .then((service) => {
        updateService(service);
      })
      .catch((error) => console.log('Something happened!'));

    getRandomServiceCategory()
      .then((services) => {
        updateOtherService(services);
      })
      .catch((error) => console.log('Something happened!'));
  }, [slug]);

  return (
    <>
      <SEO
        title={`${service?.name} -- Beauty Plus Brow Studio`}
        description={`${service?.details}`}
        name={'Beauty Plus Brow Studio'}
        type={'page'}
      />
      <section
        className={`page-banner text-white ${styles['page-banner']} d-flex align-items-center`}
        style={{
          backgroundImage: `url(${service?.image})`,
        }}
      >
        <div className='container'>
          <div className='banner-inner d-flex justify-content-center'>
            <h1 className='page-title'>{service?.name}</h1>
          </div>
        </div>
      </section>

      <section className={`${styles['service-details']} pt-45 bg-light-pink`}>
        <div className='container'>
          {service != null && (
            <div className='row d-flex justify-content-center'>
              <div className='col-lg-12'>
                <div className={styles['service-details-content-wrap']}>
                  <div className={`${styles['service-details-content']}`}>
                    <h3>{service.name}</h3>
                    {service.details.map((detail) => {
                      return (
                        <p className={`lead ${styles['lead']}`}>{detail}</p>
                      );
                    })}

                    <section className='pricing-section rel z-1 pt-5'>
                      <div className={styles['pricing-wrap']}>
                        <div className='container'>
                          <div className='row'>
                            {service.service_set.map((subService) => {
                              return (
                                <div className='col-lg-6' key={subService.id}>
                                  <div className={`${styles['pricing-item']}`}>
                                    <div className={styles['pricing-content']}>
                                      <div className={styles['pricing-title']}>
                                        <h5
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            return subService.details !== ''
                                              ? HandleSubService(subService)
                                              : false;
                                          }}
                                        >
                                          {subService.name}
                                        </h5>
                                        <span className={styles['price']}>
                                          {subService.price}
                                        </span>
                                      </div>
                                      {subService.details !== '' && (
                                        <p>{subService.details}</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className='service-middle-images mt-5'>
                    <div className='row'>
                      {service.serviceimage_set?.map((subService) => {
                        return (
                          <div
                            className={`col-lg-4 col-sm-6 ${styles['service-image']}`}
                            key={subService.id}
                          >
                            <div className={styles['service-item']}>
                              <img src={subService.image} alt='Service' />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className={styles['service-details-content-wrap']}>
                  <hr />
                  <div className={`${styles['next-prev-service']} mt-5 mb-5`}>
                    <div
                      className={` ${styles['nps-item']} ${styles['prev-item']}`}
                    >
                      <a href='service-details.html'>
                        <img src={otherServices[0]?.image} alt='Servce' />
                      </a>
                      <div className={`${styles['nps-content']}`}>
                        <h6>{otherServices[0]?.name}</h6>
                        {/* <span>Services</span> */}
                        <Link
                          to={`/services/${otherServices[0]?.slug}`}
                          className={`read-more ${styles['read-more']}`}
                        >
                          read more <i className='bi bi-arrow-right'></i>
                        </Link>
                      </div>
                    </div>
                    <Link
                      to={'/services'}
                      className={`all-services ${styles['all-services']}`}
                    >
                      <i className='bi bi-grid-fill'></i>
                    </Link>
                    <div
                      className={`nps-item next-item ${styles['nps-item']} ${styles['next-item']}`}
                    >
                      <div className={`nps-content ${styles['nps-content']}`}>
                        <h6>{otherServices[1]?.name}</h6>
                        {/* <span>Services</span> */}
                        <Link
                          to={`/services/${otherServices[1]?.slug}`}
                          className={`read-more ${styles['read-more']}`}
                        >
                          read more <i className='bi bi-arrow-right'></i>
                        </Link>
                      </div>
                      <a href='service-details.html'>
                        <img src={otherServices[1]?.image} alt='Servce' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {service == null && <h1>Service Not Found</h1>}
        </div>
      </section>

      <SubServiceModel
        show={subService.show}
        onHide={() =>
          setSubService((prevService) => {
            return { ...prevService, show: false };
          })
        }
        service={subService.service}
      />
    </>
  );
};

export default ServiceDetails;
