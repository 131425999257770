import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './SubServceModel.module.css';

const SubServiceModel = (props) => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='bg-light-pink'>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h4 className='color-pink pt-md-2 pl-2 pt-5'>
            {props.service?.name} - <small>${props.service?.price}</small>
          </h4>
        </Modal.Title>
        <i
          className='bi bi-x-lg'
          style={{
            position: 'absolute',
            right: '30px',
            top: '15px',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
          onClick={props.onHide}
        />
      </Modal.Header>
      <Modal.Body className='bg-light-pink'>
        <Container className='text-center'>
          <h3 className='lead pt-1 pb-3'>{props.service?.details}</h3>

          <Row className='justify-content-center mb-5'>
            {props.service?.subserviceimage_set.map((service) => {
              return (
                <Col md={6} sm={6} className={styles['category-section']}>
                  <div
                    className={`${styles['category-item']} ${styles['style-two']}`}
                    key={service.id}
                  >
                    <img src={service.image} alt={service.name} />
                    <div className={styles['category-title']}>
                      <h4>{service.text}</h4>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SubServiceModel;
