import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './Services.module.css';

import pageBanner from './../assets/images/about/salon.jpeg';
import serviceBG from './../assets/images/shapes/service-bg.png';

import { getServiceCategories } from '../services/api/Service';
import SEO from '../SEO/SEO';

const Services = (props) => {
  const [services, updateServices] = useState([]);
  const [columnSize, updateColumnSize] = useState(0);
  const columns = [0, 1, 2];

  useEffect(() => {
    getServiceCategories()
      .then((services) => {
        updateServices(services);
        updateColumnSize(Math.floor(services.length / 3));
      })
      .catch((error) => console.log('Something happened!'));
  }, []);

  const content = columns.map((column, columnIndex) => {
    const firstColumn = 0;
    const start =
      columnIndex !== firstColumn
        ? column * columnSize + 1
        : column * columnSize;
    const end = column * columnSize + columnSize + 1;

    const categories = services.slice(start, end);

    return (
      <div
        className='col-lg-4 col-md-7 col-sm-6 row d-flex justify-content-start'
        key={column}
      >
        {categories.map((serviceCategory, index) => {
          return (
            <div className='col-lg-12 col-sm-12' key={serviceCategory.id}>
              <div className={styles['service-item']}>
                <img src={serviceCategory.image} alt={serviceCategory.name} />
                <div className={styles['service-content']}>
                  <h5>
                    <a href='service-details.html'>
                      {serviceCategory.name} Services
                    </a>
                  </h5>
                  {serviceCategory.require_appointment && (
                    <small>(Need Appointment)</small>
                  )}
                  <Link
                    to={`/services/${serviceCategory.slug}`}
                    className={`read-more ${styles['read-more']}`}
                  >
                    read more <i className='bi bi-arrow-right'></i>
                  </Link>
                  <div className={`widget ${styles['price-list']}`}>
                    <ul>
                      {serviceCategory.service_set.map((service, index2) => {
                        return (
                          <li key={service.id}>
                            <div className={styles.title}>{service.name}</div>
                            <div className={styles['price-dots']}></div>
                            <div className={styles.price}>{service.price}</div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <>
      <SEO
        title={'Services -- Beauty Plus Brow Studio'}
        description={`Beauty Plus Brow Specializing in enhancing natural beauty
        using semi permanent makeup technique to make you feel
        confident & look younger, check out list of our services.`}
        name={'Beauty Plus Brow Studio'}
        type={'page'}
      />
      <section
        className='page-banner text-white pt-165 pb-130 bg-light-pink'
        style={{
          backgroundImage: `url(${pageBanner})`,
        }}
      ></section>

      <section className='pt-80 pb-90 bg-light-pink'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='section-title text-center mb-65'>
                <img className='bg-image' src={serviceBG} alt='Leaf' />
                <span className='sub-title'>What We Offer</span>
                <h3>
                  Beauty Plus Brow Specializing in enhancing natural beauty
                  using semi permanent makeup technique to make you feel
                  confident & look younger
                </h3>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>{content}</div>
        </div>
      </section>
    </>
  );
};

export default Services;
