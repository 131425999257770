import React, { useEffect, useState, useCallback } from 'react';
import PhotoAlbum from 'react-photo-album';

import styles from './Gallery.module.css';
import { loadImages } from '../services/api/Gallery';
import SEO from '../SEO/SEO';

const Gallery = (props) => {
  const [gallery, updateGallery] = useState([]);
  const [isEnd, updateGalleryEnd] = useState(false);

  useEffect(() => {
    loadMore(0);
  }, []);

  const loadMore = useCallback((offset) => {
    if (!isEnd)
      loadImages(offset, 6)
        .then((data) => {
          if (data.length > 0)
            updateGallery((previousData) => {
              return [...previousData, ...data];
            });
          else updateGalleryEnd(true);
        })
        .catch((error) => {});
  }, []);

  const handleLoadMore = () => {
    const offset = gallery.length;
    loadMore(offset);
  };

  return (
    <>
      <SEO
        title={'Gallery -- Beauty Plus Brow Studio'}
        description={`Welcome to Beauty Plus Brow Studio, here you can find our latest work.`}
        name={'Beauty Plus Brow Studio'}
        type={'page'}
      />
      <section className='portfolio-section pt-80 pb-80 bg-light-pink '>
        <div className='container'>
          <div className='section-title text-center'>
            <span className='bg-text'>Gallery</span>
            <span className='sub-title'>Photo Gallery</span>
            <h2>Latest Work</h2>
          </div>
          <div className='mt-100'>
            <PhotoAlbum
              photos={gallery}
              layout='masonry'
              renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                <div className={styles['portfolio-item']} style={wrapperStyle}>
                  {renderDefaultPhoto({ wrapped: true })}
                </div>
              )}
              columns={(containerWidth) => {
                if (containerWidth < 375) return 1;
                if (containerWidth < 786) return 2;
                if (containerWidth < 1200) return 3;
                return 4;
              }}
            />
          </div>

          <div className='col-lg-12 pt-45'>
            <div className={`${styles['project-btn']} text-center`}>
              {!isEnd && (
                <button className='theme-btn' onClick={handleLoadMore}>
                  Load More<i className='bi bi-arrow-right'></i>
                </button>
              )}
              {isEnd && <h6>No more images to load!</h6>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
